<template>
    <font-awesome-icon icon="info-circle" @click="showDialog()" size="lg" class="icon-button p-text-primary" title="Detalhar" />

    <Dialog v-model:visible="display" no-close-on-backdrop :style="{ width: '70vw' }" :maximizable="true">
        <template #header>
            <h3>Detalhes - Interlocutor</h3>
        </template>

        <div class="grid">
            <div class="col-6">
                <div class="mb-4">
                    <detalhe titulo="Id: " :size="sizeForm">{{ interlocutor.id }}</detalhe>
                    <detalhe titulo="Nome: " :size="sizeForm">{{ interlocutor.nome }}</detalhe>
                    <detalhe titulo="Cargo: " :size="sizeForm">{{ interlocutor.cargo }}</detalhe>
                    <detalhe titulo="Informações Importantes: " :size="sizeForm">{{ interlocutor.informacoesImportantes }}</detalhe>
                    <detalhe titulo="CPF: " :size="sizeForm">{{ interlocutor.cpf }}</detalhe>
                </div>
            </div>
            <div class="col-6">
                <div class="mb-4">
                    <detalhe titulo="Telefone Institucional: " :size="sizeForm">{{ interlocutor.telefoneInstitucional }}</detalhe>
                    <detalhe titulo="E-mail Institucional: " :size="sizeForm">{{ interlocutor.emailInstitucional }}</detalhe>
                    <detalhe titulo="Telefone Pessoal: " :size="sizeForm">{{ interlocutor.telefonePessoal }}</detalhe>
                    <detalhe titulo="E-mail Pessoal: " :size="sizeForm">{{ interlocutor.emailPessoal }}</detalhe>
                    <detalhe titulo="CEP: " :size="sizeForm">{{ interlocutor.cep }}</detalhe>
                    <detalhe titulo="Endereço: " :size="sizeForm">{{ interlocutor.endereco }}</detalhe>
                    <detalhe titulo="Número: " :size="sizeForm">{{ interlocutor.numero }}</detalhe>
                    <detalhe titulo="Bairro: " :size="sizeForm">{{ interlocutor.bairro }}</detalhe>
                    <detalhe titulo="Cidade: " :size="sizeForm">{{ interlocutor.cidade }}</detalhe>
                    <detalhe titulo="Complemento: " :size="sizeForm">{{ interlocutor.complemento }}</detalhe>
                    <detalhe titulo="UF: " :size="sizeForm">{{ interlocutor.uf }}</detalhe>
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Fechar" autofocus @click="closeDialog()" />
        </template>
    </Dialog>
</template>

<script>
import Services from '../../service.js';

export default {
    props: {
        idInterlocutor: {
            type: Number,
        },
    },

    data() {
        return {
            display: false,
            interlocutor: {},
        };
    },

    methods: {
        showDialog() {
            this.obterInterlocutor();
            this.display = true;
        },

        closeDialog() {
            this.display = false;
        },

        obterInterlocutor() {
            this.$store.dispatch('addRequest');
            Services.SelectByIdInterlocutores(this.idInterlocutor).then((response) => {
                if (response && response.success) {
                    this.interlocutor = response.data;
                } else {
                    this.interlocutor = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    computed: {
        sizeForm() {
            return '200';
        },
    },
};
</script>