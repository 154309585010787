<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div class="flex">
                    <h5>Parça - Listagem dos Interlocutores</h5>
                </div>

                <!--msg-box :itens="mensagens"></msg-box-->

                <tabela :data="lista" :operacao="1">
                    <template #botoes>
                        <CadInterlocutor @atualizarLista="listar()" :operacao="1" :idInterlocutor="0" v-if="$temAcessoView('MAPAPARC-INT-01') && !externo"></CadInterlocutor>
                    </template>

                    <template #conteudo>
                        <Column header="Operações" headerStyle="width: 8em">
                            <template #body="slotProps">
                                <Detalhes :idInterlocutor="slotProps.data.id"></Detalhes>
                                <CadInterlocutor @atualizarLista="listar()" :operacao="2" :idInterlocutor="slotProps.data.id" v-if="$temAcessoView('MAPAPARC-INT-02') && !externo"></CadInterlocutor>
                                <font-awesome-icon icon="trash-alt" @click="confirmaDelete(slotProps.data.id)" size="lg" class="icon-button ml-2" title="Deletar" style="color: red" v-if="$temAcessoView('MAPAPARC-INT-03') && !externo" />
                            </template>
                        </Column>
                        <Column field="id" header="Id" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.id }}
                            </template>
                        </Column>
                        <Column field="nome" header="Nome" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.nome }}
                            </template>
                        </Column>
                        <Column field="cargo" header="Cargo" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.cargo }}
                            </template>
                        </Column>
                        <Column field="telefoneInstitucional" header="Telefone Institucional" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.telefoneInstitucional }}
                            </template>
                        </Column>
                        <Column field="emailInstitucional" header="E-mail Institucional" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.emailInstitucional }}
                            </template>
                        </Column>
                        <Column field="cpf" header="CPF" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.cpf }}
                            </template>
                        </Column>
                    </template>
                </tabela>
            </div>
        </div>
    </div>
    <Dialog header="Confirmação" v-model:visible="showDialogExcluir" :style="{ width: '350px' }" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-question-circle mr-3" style="font-size: 2rem" />
            <span>Confirma Exclusão?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" @click="closeConfirmation(false)" class="p-button-text" />
            <Button label="Sim" icon="pi pi-check" @click="closeConfirmation(true)" class="p-button-text" autofocus />
        </template>
    </Dialog>
    <router-view></router-view>
</template>

<script>
import Services from '../../service.js';
import Detalhes from '../detalhes/DetalhesInterlocutor.vue';
import CadInterlocutor from '../cadastro/CadInterlocutor.vue';

export default {
    components: {
        Detalhes,
        CadInterlocutor,
    },

    props: {
        filtroInterlocutor: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            lista: [],
            showDialogExcluir: false,
            idExclusao: 0,
            externo: false,
        };
    },

    methods: {
        listar() {
            this.listarInterlocutores();
        },

        listarInterlocutores() {
            this.$store.dispatch('addRequest');
            var filtro;
            if (this.filtroInterlocutor == null) {
                filtro = {
                    cpf: '',
                    nomeInterlocutor: '',
                };
            } else {
                filtro = {
                    cpf: this.filtroInterlocutor.cpf,
                    nomeInterlocutor: this.filtroInterlocutor.nomeInterlocutor,
                };
            }
            Services.SelectAllInterlocutores(filtro).then((response) => {
                if (response && response.success) {
                    this.lista = response.data;
                } else {
                    this.lista = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        confirmaDelete(id) {
            this.showDialogExcluir = true;
            this.idExclusao = id;
        },

        closeConfirmation(confirmacao) {
            if (confirmacao) {
                this.deletar();
            }
            this.showDialogExcluir = false;
        },

        deletar() {
            this.$store.dispatch('addRequest');
            Services.DeletarInterlocutor(this.idExclusao).then((response) => {
                if (response && response.success) {
                    this.listar();
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        if (this.filtroInterlocutor != null) {
            this.externo = true;
        }
        this.listar();
    },
};
</script>