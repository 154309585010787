<template>
    <Button label="Adicionar" icon="pi pi-plus" class="ml-2" @click="showDialog()" v-if="operacao == '1'" />
    <font-awesome-icon icon="pen" @click="showDialog()" size="lg" class="icon-button p-text-info ml-2" title="Editar" v-if="operacao == '2'" />

    <Dialog v-model:visible="display" no-close-on-backdrop :style="{ width: '45vw' }" :maximizable="false" :closable="false">
        <template #header>
            <h3>{{ operacaoTexto }} - Interlocutores: {{ nome }}</h3>
        </template>

        <div class="formgrid grid p-fluid">
            <div class="field col-9">
                <label class="required">Nome</label>
                <InputText type="text" v-model="nome" />
            </div>
            <div class="field col-3 field-checkbox">
                <Checkbox :binary="true" v-model="ckAssinaAcordo" />
                <label for="accept">Assina Acordo Parceria?</label>
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col-4">
                <label class="required">Cargo</label>
                <InputText type="text" v-model="cargo" />
            </div>
            <div class="field col-4">
                <label class="required">CPF</label>
                <InputMask v-model="cpf" mask="999.999.999-99" :unmask="true" placeholder="CPF" />
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col-4">
                <label class="required">Cep</label>
                <div class="p-inputgroup">
                    <InputMask v-model="cep" mask="99999-999" :unmask="true" placeholder="CEP" />
                    <Button title="Buscar Cep nos Correios" icon="pi pi-search-plus" @click="ConsultaCep()" />
                </div>
            </div>
            <div class="field col-6">
                <label class="required">Endereço</label>
                <InputText type="text" v-model="endereco" />
            </div>
            <div class="field col-2">
                <label class="required">Número</label>
                <InputText type="text" v-model="numero" />
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col-4">
                <label class="required">Cidade</label>
                <InputText type="text" v-model="cidade" />
            </div>
            <div class="field col-3">
                <label class="required">Bairro</label>
                <InputText type="text" v-model="bairro" />
            </div>
            <div class="field col-3">
                <label>Complemento</label>
                <InputText type="text" v-model="interlocutor.complemento" />
            </div>
            <div class="field col-2">
                <label class="required">UF</label>
                <InputText type="text" v-model="uf" />
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col-3">
                <label class="required">Telefone Institucional</label>
                <InputMask v-model="telefoneInstitucional" mask="(99)99999-9999" :unmask="true" :autoClear="false" />
            </div>
            <div class="field col-9">
                <label class="required">E-mail Institucional</label>
                <InputText type="text" v-model="emailInstitucional" />
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col-3">
                <label>Telefone Pessoal</label>
                <InputMask v-model="telefonePessoal" mask="(99)99999-9999" :unmask="true" :autoClear="false" />
            </div>
            <div class="field col-9">
                <label>E-mail Pessoal</label>
                <InputText type="text" v-model="interlocutor.emailPessoal" />
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col-12">
                <label>Informações Importantes</label>
                <Textarea type="text" v-model="interlocutor.informacoesImportantes" rows="3" />
            </div>
        </div>

        <template #footer>
            <Button label="Salvar" @click="salvar()" :disabled="v$.$invalid" />
            <Button label="Fechar" autofocus @click="closeDialog()" />
        </template>
    </Dialog>
</template>

<script>
import Services from '../../service.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    props: {
        idInterlocutor: {
            type: Object,
        },
        operacao: {
            type: Number,
        },
    },

    setup() {
        return { v$: useVuelidate() };
    },

    validations() {
        return {
            nome: { required },
            cpf: { required },
            telefoneInstitucional: { required },
            cargo: { required },
            cep: { required },
            endereco: { required },
            numero: { required },
            cidade: { required },
            bairro: { required },
            uf: { required },
            emailInstitucional: { required },
        };
    },

    watch: {
        'interlocutor.nome'() {
            this.nome = null;
            if (this.interlocutor.nome != '') {
                this.nome = this.interlocutor.nome;
            }
        },
    },

    data() {
        return {
            display: false,
            nome: null,
            cpf: null,
            telefoneInstitucional: '',
            telefonePessoal: '',
            ckAssinaAcordo: false,
            operacaoTexto: 'Cadastro',
            cargo: '',
            cep: '',
            endereco: '',
            numero: '',
            cidade: '',
            bairro: '',
            uf: '',
            emailInstitucional: '',

            interlocutor: {
                id: 0,
                nome: '',
                cargo: '',
                telefoneInstitucional: '',
                emailInstitucional: '',
                telefonePessoal: '',
                emailPessoal: '',
                assinaAcordoParceria: 0,
                informacoesImportantes: '',
                cpf: '',
                cep: '',
                endereco: '',
                numero: '',
                complemento: '',
                bairro: '',
                cidade: '',
                uf: '',
            },
        };
    },

    methods: {
        showDialog() {
            this.display = true;
            this.nome = null;

            if (this.operacao == 2) {
                this.operacaoTexto = 'Edição';
                this.obterInterlocutor();
            }
        },

        limparCampos() {
            (this.interlocutor.id = 0),
                (this.nome = ''),
                (this.interlocutor.cargo = ''),
                (this.telefoneInstitucional = ''),
                (this.interlocutor.emailInstitucional = ''),
                (this.telefonePessoal = ''),
                (this.interlocutor.emailPessoal = ''),
                (this.interlocutor.assinaAcordoParceria = 0),
                (this.interlocutor.informacoesImportantes = ''),
                (this.cpf = ''),
                (this.ckAssinaAcordo = false),
                (this.interlocutor.cep = ''),
                (this.interlocutor.endereco = ''),
                (this.interlocutor.numero = ''),
                (this.interlocutor.complemento = ''),
                (this.interlocutor.bairro = ''),
                (this.interlocutor.cidade = ''),
                (this.interlocutor.uf = ''),
                (this.interlocutor.telefoneInstitucional = ''),
                (this.interlocutor.telefonePessoal = '');

                (this.cargo = ''),
                (this.cep = ''),
                (this.endereco = ''),
                (this.numero = ''),
                (this.cidade = ''),
                (this.bairro = ''),
                (this.uf = ''),
                (this.emailInstitucional = '')

        },

        closeDialog() {
            this.display = false;
            this.limparCampos();
        },

        obterInterlocutor() {
            this.$store.dispatch('addRequest');
            Services.SelectByIdInterlocutores(this.idInterlocutor).then((response) => {
                if (response && response.success) {
                    this.interlocutor = response.data;
                    this.nome = this.interlocutor.nome;
                    this.cpf = this.interlocutor.cpf;
                    this.telefoneInstitucional = this.interlocutor.telefoneInstitucional;
                    this.telefonePessoal = this.interlocutor.telefonePessoal;
                    this.cargo = this.interlocutor.cargo;
                    this.cep = this.interlocutor.cep;
                    this.endereco = this.interlocutor.endereco;
                    this.numero = this.interlocutor.numero;
                    this.cidade = this.interlocutor.cidade;
                    this.bairro = this.interlocutor.bairro;
                    this.uf = this.interlocutor.uf;
                    this.emailInstitucional = this.interlocutor.emailInstitucional;
                    if (this.interlocutor.assinaAcordoParceria == 1) {
                        this.ckAssinaAcordo = true;
                    }
                } else {
                    this.interlocutor = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        salvar() {
            this.interlocutor.assinaAcordoParceria = 0;
            if (this.ckAssinaAcordo) {
                this.interlocutor.assinaAcordoParceria = 1;
            }
            this.interlocutor.nome = this.nome;
            this.interlocutor.cpf = this.cpf;
            this.interlocutor.telefoneInstitucional = this.telefoneInstitucional;
            this.interlocutor.telefonePessoal = this.telefonePessoal;

            this.interlocutor.cargo = this.cargo;
            this.interlocutor.cep = this.cep;
            this.interlocutor.endereco = this.endereco;
            this.interlocutor.numero = this.numero;
            this.interlocutor.cidade = this.cidade;
            this.interlocutor.bairro = this.bairro;
            this.interlocutor.uf = this.uf;
            this.interlocutor.emailInstitucional = this.emailInstitucional;

            if (this.operacao == 1) {
                this.$store.dispatch('addRequest');
                Services.InserirInterlocutor(this.interlocutor).then((response) => {
                    if (response && response.success) {
                        this.limparCampos();
                        this.$emit('atualizarLista');
                    }
                    this.$store.dispatch('removeRequest');
                });
            } else {
                this.$store.dispatch('addRequest');
                Services.AlterarInterlocutor(this.interlocutor).then((response) => {
                    if (response && response.success) {
                        this.$emit('atualizarLista');
                    }
                    this.$store.dispatch('removeRequest');
                });
            }

            this.closeDialog();
        },

        ConsultaCep() {
            var cep = this.cep.replace('-', '');
            if (cep.length == 8) {
                this.$store.dispatch('addRequest');
                Services.ConsultaCepInterlocutor(cep).then((response) => {
                    if (response && response.success) {
                        this.cep = cep;
                        this.endereco = response.data.logradouro;
                        this.bairro = response.data.bairro;
                        this.cidade = response.data.localidade;
                        this.uf = response.data.uf;
                    } else {
                        alert('Cep Informado não foi Localizado');
                    }
                    this.$store.dispatch('removeRequest');
                });
            } else {
                alert('Cep Informado inválido');
            }
        },
    },
};
</script>